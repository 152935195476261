import React, {Fragment} from 'react';
import getConfig from 'next/config';
import {OrganizationConstants} from '../../../adapters/helpers/Constants';
const { publicRuntimeConfig = {} } = getConfig() || {};
import { setProfileData } from './localstorage.helpers'

export const renderPGDataLayer = (languageCode, countryCode, productVariant = undefined) => {
  if(typeof window !== 'undefined' && !window.PGdataLayer.user){
    setProfileData();
  }
    return (
        <Fragment>
            <script type='text/javascript' dangerouslySetInnerHTML={{__html: `
        var PGdataLayer = {
                            'page' : {
                              'url' : '',
                              'title' : '',
                            },

                            'GTM': {
                              'SiteCountry':'${countryCode}',
                              'GoogleAnalyticsSiteSpeedSampleRate':'regular',
                              'GoogleAnalyticsAllowLinker':'false',
                              'GoogleAnalyticsOptimizeContainerID':'${publicRuntimeConfig.GA_OPTIMIZE_CONTAINER_ID}',
                              'GoogleReCaptcha':'false',
                              'GoogleAnalyticsReportingView':'${publicRuntimeConfig.GA_REPORTING_VIEW}',
                              'GhosteryOverlay': '',
                              'GhosteryOverlayID': '',
                              'SiteHost': 'Azure Devops',
                              'SiteEnvironment': '${publicRuntimeConfig.SITE_ENVIRONMENT}',
                              'SiteTechnicalAgency': 'PGone',
                              'SiteLocalContainer': '${publicRuntimeConfig.SITE_LOCALCONTAINER}',
                              'GoogleAnalyticsLocal': '${publicRuntimeConfig.GA_TRACKING_CODE}',
                              'ConsentOverlay': '${publicRuntimeConfig.CONSENT_OVERLAY}',
                              'ConsentOverlayID': '${publicRuntimeConfig.CONSENT_OVERLAY_ID}',
                              'SitePrivacyProtection': '${publicRuntimeConfig.SITE_PRIVACY_PROTECTION}',
                              'SiteTouchpoint': '${publicRuntimeConfig.DL_TOUCHPOINT_ID}',
                              'SiteBrand':  'OralB',
                              'SiteLanguage':  '${languageCode}',
                              'SitePlatform': 'ModernWeb',
                              'SiteStatus': '${publicRuntimeConfig.DL_SITE_STATUS}',
                              'FacebookRemarketingID': '${publicRuntimeConfig.FACEBOOK_REMARKETING_ID}'
                            }
                          };
        `}}>
            </script>
            { productVariant &&
                <script type='text/javascript' dangerouslySetInnerHTML={{__html: `
                    PGdataLayer.product = {
                                          'gtin': '${productVariant?.fields?.sku || ''}',
                                          'name': '${productVariant?.fields?.name || ''}',
                                          'brand': '${OrganizationConstants.brand || ''}',
                                          'upc': '${productVariant?.fields?.sku || ''}'
                                        };
                    `}}>
                </script>
            }
            <link rel='preconnect' crossOrigin='crossorigin' href='https://www.googletagmanager.com' />
            <script defer dangerouslySetInnerHTML={{__html: `
        (
                function(w,d,s,l,i) {
                  w[l]=w[l]||[];w[l].push({
                    'gtm.start' : new Date().getTime(),
                    event : 'gtm.js'
                  });
                  var f = d.getElementsByTagName(s)[0],
                  j=d.createElement(s),
                  dl = l!='dataLayer'?'&l='+l:'';

                  j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                  f.parentNode.insertBefore(j,f);
                }
            )(window,document,'script','dataLayer','${publicRuntimeConfig.GTM_TRACKING_ID}');
        `,}}>
            </script>
        </Fragment>
    )
};